<template>
	<div class="page--padding text-center d-flex align-center justify-center error404">
		<section class="section--padding">
			<v-container>
				<v-row>
					<v-col>
						<img src="@/assets/error/404.svg" alt="Error 404">
						<h3 class="mt-4">Page not found</h3>
						<p class="size2 mt-8">Seems like this piece of chocolate is placed somewhere else.</p>
						<btn-link :to="{ name: 'home' }" class="mx-auto mt-14" label="Home page"/>
					</v-col>
				</v-row>
			</v-container>
		</section>
	</div>
</template>

<script>
    import BtnLink from '../components/btn-link';

    export default {
        name: "Error404",
        components: {
            BtnLink,
        }
    }
</script>

<style lang="scss">
</style>